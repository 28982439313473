   body{
     margin: 0;
   } 

.padding10{
  padding: 10px; 
}

    section{
        margin: 10px 0px;
    }


    .linethrough {
      text-decoration: line-through
    }

    @keyframes gradientAnimation {
      0% {
        background-position: left center;
      }
      50% {
        background-position: right center;
      }
      100% {
        background-position: left center;
      }
    }

    .ms-textAlignRight{
      text-align: right;
    }

    .ms-textAlignLeft{
      text-align: left;
    }